@import "~antd/dist/antd.css";

.ant-progress-show-info .ant-progress-outer {
  padding-right: 4.75em !important;
  margin-right: -4.75em !important;
}

.ant-progress-text {
  width: 4em !important;
}

em {
  background-color: yellow;
}
.EventRiskLevel_High td:first-of-type {
  border-left: 5px solid red;
}

.EventRiskLevel_Medium td:first-of-type {
  border-left: 5px solid orange;
}

.EventRiskLevel_Low td:first-of-type {
  border-left: 5px solid gray;
}
